import _, { constant } from 'lodash';
import { from, of } from 'rxjs';
import { groupBy, mergeMap } from 'rxjs/operators';
import { SortOrder } from '../models/common.model';


export const splitCamelCase = (str: string): string => {
    return str ? str.replace(/([a-z0-9])([A-Z])/g, '$1 $2') : str;
}

export const InitialCapitalCase = (str: string): string => {
    return str ? str.split(' ').map(((w: string) => w.charAt(0).toUpperCase() + w.substring(1))).join(' ') : str;
}

export const splitInitCapitalCamelCase = (str: string): string => {
    return str ? InitialCapitalCase(str.replace(/([a-z0-9])([A-Z])/g, '$1 $2')) : str;
}

export const sortBy = (input: Array<any>, key: string) => {
    return _.sortBy(input, [function (o) { return o[key]; }]);
}
export const sortByDate = (input: Array<any>, key: string) => {
    return _.sortBy(input, [function (o) { return o[key]; }]);
}
/// 
/// 
/// @param {Array<any>} input - 
/// @param {Array<SortOrder>} sortParams - 
/// @returns {any} - 
export const orderBy = (input: Array<any>, sortParams: Array<SortOrder>) => {
    return _.orderBy(input, sortParams.map(param => param.key), sortParams.map(param => param.order));
}

export const GetValidDateOrNull = (date: string) => {
    if (date && new Date(date).getFullYear() < 1900) {
        return null;
    }
    return date == "0001-01-01" || date == "0001-01-01T00:00:00" || date == "null" || date == "undefined" ? null : date;
}

export const GroupByKey = (allItems: Array<any>, groupKey: string, splitCamelCase = false) => {

    return new Promise((resolve, reject) => {
        const itemGroups:Array<any> = [];
        if (!groupKey || !allItems?.length) {
            resolve(itemGroups);
            return;
        }
        const items = from(allItems || []).pipe(
            groupBy((doc) => doc[groupKey]),
            mergeMap((group) => {
                var items = [];
                group.forEach(item => items.push(item));
                return of((
                    {
                        key: splitCamelCase ? splitInitCapitalCamelCase(group?.key || "Other") : group?.key,
                        items: items//orderBy(items, [{ key: 'expiryDate', order: 'desc' }])
                    }
                ));// group.pipe(toArray())
            })
        );
        const subscribe = items.subscribe((val) => {
            itemGroups.push(val);
        });
        itemGroups.forEach(element => {
            element[groupKey] = "" + splitInitCapitalCamelCase(element[groupKey])
        });
        resolve(itemGroups);
    })
}