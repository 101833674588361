import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import {SelectVehicleComponent} from "./select-vehicle/select-vehicle.component";
import { UpdateProPicComponent } from '../../user/components/update-pro-pic/update-pro-pic.component';
import { LetterHeadComponent } from './letter-head/letter-head.component';


const components = [
  AlertComponent,
  SelectVehicleComponent,UpdateProPicComponent,LetterHeadComponent
]

@NgModule({
  declarations: [components],
  exports: [components],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule
  ]
})
export class ComponentsModule { }
