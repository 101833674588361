import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/modules/user/services/user.service';

@Component({
  selector: 'letter-head',
  templateUrl: './letter-head.component.html',
  styleUrls: ['./letter-head.component.scss'],
})
export class LetterHeadComponent implements OnInit {
  companyInfo: any;

  constructor(public userService:UserService) { }

  ngOnInit() {
    this.userService.getCompanyInfo().subscribe((res:any)=>{
      this.companyInfo=res.output
    })
  }

}
