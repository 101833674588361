import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ServicesModule } from './services/services.module';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { AppintmentResponseComponent } from './popups/appintment-response/appintment-response.component';
import { SignaturePadComponent } from './components/signature-pad/signature-pad.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { MatRadioModule } from '@angular/material/radio';
import { NotificationPopoverComponent } from './popups/notification-popover/notification-popover.component';
import { ComponentsModule } from './components/components.module';
import { CurrentUserComponent } from './popups/current-user/current-user.component';



const components = [
  NavbarComponent, AppintmentResponseComponent, SignaturePadComponent, NotificationPopoverComponent, CurrentUserComponent
]

@NgModule({
  declarations: [components],
  exports: [components],
  imports: [SignaturePadModule,
    CommonModule, FormsModule, ReactiveFormsModule, IonicModule, ServicesModule,
    MatNativeDateModule, MatDatepickerModule, MatInputModule, MatRadioModule, ComponentsModule
  ]
})
export class RoadTestModule { }
