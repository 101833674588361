import { Injectable } from '@angular/core';
import {StorageService} from './storage.service';
import { Router } from '@angular/router';
import { HttpService } from './http.service';
import { Login } from '../../../models/auth';




@Injectable()
export class UserService {

  constructor(private httpService:HttpService,public storageService:StorageService,public router:Router) { }

  login(credentials:Login){
    return this.httpService.postWithoutToken("Auth/InstructorLogin",credentials);
    //return this.httpService.postWithoutToken("/api/Auth/InstructorLogin",credentials);
  }

  logout(){
    this.storageService.removeLocal('user');
    this.storageService.removeLocal('signature');
    this.router.navigate(['/login']).then(()=>{
      setTimeout(() => {
        location.reload();
      }, 500);
    });
  }

  changePassword(body){
    return this.httpService.post("Auth/ChangePassword",body);
  }

  updateProfile(body){
    return this.httpService.post("Auth/EditUser",body);
  }

  getUserDetails(){
    return this.httpService.get("Auth/GetLoggedInUserDetails",{});
  }

  getLoggedInUserProfile(){
    return this.httpService.get("Auth/GetLoggedInUserProfile",{});
  }

  getCompanyInfo(){
    return this.httpService.get("Company/GetCompanyInfo",{});
  }

  updateProPic(body){
    return this.httpService.postfile("Auth/UpdateUserProfilePicture",body);
  }

  fogotPassword(body){
    return this.httpService.postWithoutToken("Auth/ForgotPassword",body);
  }

  getNotification(body){
    return this.httpService.post("Notification/GetMultipleNotificationsRecords",body);
  }

  addNotification(body){
    return this.httpService.post("Notification/AddNotification",body);
  }

  addDeviceInfo(body) {
    return this.httpService.post("DeviceInfo/AddDeviceInfo", body);
  }

  verifyEmail(params: any){
    return this.httpService.post('Auth/VerifyEmail', params);
  }

  verifyMobile(params: any){
    return this.httpService.post('Auth/VerifyMobile', params);
  }


  confirmEmailWithOTP(body ,params: any){
    return this.httpService.postWithParams('Auth/ConfirmEmailWithOTP', body,params);
  }

  confirmMobileWithOTP(body ,params: any){
    return this.httpService.postWithParams('Auth/ConfirmMobileWithOTP', body,params);
  }
}
