import { Injectable } from '@angular/core';
import {StorageService} from './storage.service';
import { Router } from '@angular/router';
import { HttpService } from './http.service';
import { Login } from '../models/auth';




@Injectable()
export class UserService {

  constructor(private httpService:HttpService,public storageService:StorageService,public router:Router) { }

  login(credentials:Login){
    return this.httpService.postWithoutToken("Auth/Login",credentials);
  }

  logout(){
    this.storageService.removeLocal('user');
    this.storageService.removeLocal('signature');
    this.router.navigate(['/login']);
  }

  changePassword(body){
    return this.httpService.post("Auth/ChangePassword",body);
  }

  updateProfile(body){
    return this.httpService.post("Auth/EditUser",body);
  }

  getUserDetails(){
    return this.httpService.get("Auth/GetLoggedInUserDetails",{});
  }

  updateProPic(body){
    return this.httpService.postfile("Auth/UpdateUserProfilePicture",body);
  }

  getNotification(body){
    return this.httpService.post("Notification/GetMultipleNotificationsRecords",body);
  }

  addNotification(body){
    return this.httpService.post("Notification/AddNotification",body);
  }
}
