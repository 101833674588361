import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Login } from '../../../models/auth';
import { StorageService } from '../services/storage.service';
import { UserService } from '../services/user.service';




@Injectable()
export class UserProvider {

  constructor(public userService: UserService, private storageService: StorageService, public router: Router) { }

  login(credentials: Login) {

    console.log();


    // this.credentials = this.loginForm.value;

    // this.userProvider.login(this.credentials).subscribe(data=>{
    //   console.log(data);
    //   if(data['status']=="Ok"){
    //     this.storageService.localStore("user",data['output']);
    //     this.router.navigate(['/home']);

    //     //console.log(JSON.parse(this.storageService.getLocal('user')).token)
    //   }else{
    //     this.is_err=true;
    //   }

    // })
    return this.userService.login(credentials).pipe(map((data:any) => {
      console.log(data);
      if (data['status'] == "Ok") {
        this.storageService.localStore("user", data['output']);
        if(!data.output.emailConfirmed || !data.output.phoneNumberConfirmed){
          this.router.navigateByUrl('/email-phone-verify');
        }else{
          this.router.navigate(['/home']);
        }

        //console.log(JSON.parse(this.storageService.getLocal('user')).token)
      } else {
        //this.is_err=true;
      }

      return data;
    }));
  }

  logout() {
    this.userService.logout();
  }

  changePassword(body) {
    return this.userService.changePassword(body);
  }

  updateProfile(body) {
    return this.userService.updateProfile(body);
  }

  getUserDetails() {
    return this.userService.getUserDetails();
  }

  updateProPic(body) {
    return this.userService.updateProPic(body);
  }

  getLoggedInUserProfile(){
    return this.userService.getLoggedInUserProfile();
  }
}
