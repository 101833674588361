import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { UserProvider } from 'src/app/modules/user/providers/user.provider';
import { StorageService } from '../../services/storage.service';
import { NotificationPopoverComponent } from '../../popups/notification-popover/notification-popover.component';
import { UserService } from '../../services/user.service';
import { CurrentUserComponent } from 'src/app/modules/roadTest/popups/current-user/current-user.component';
@Component({
  selector: 'app-nav-bar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

  userData: any;
  notifications=[];

  constructor(public userProvider:UserProvider,public router:Router,public storageService:StorageService,
    public popoverController: PopoverController,public userService: UserService) { }

  ngOnInit() {
    this.userData = JSON.parse(this.storageService.getLocal('user'));

    let body={
      pageNumber: 1,
      pageSize: 6
    }
    this.userService.getNotification(body).subscribe(data=>{
      this.notifications=data['output']
    })
  }

  openNotification(event){
    this.presentPopover(event);
  }

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: NotificationPopoverComponent,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
      componentProps:{'notifications':this.notifications}
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
  }

  logout(){
    this.userProvider.logout();
  }

  gotoProfile(){
    this.router.navigate(['/profile']);
  }

  async openUserInfoPopup(ev: any) {
    const popover = await this.popoverController.create({
      component: CurrentUserComponent,
      cssClass: 'current-user-popover-class',
      event: ev,
      translucent: true
    });
    return await popover.present();
  }
}
