import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { HttpService } from './http.service';
import { StorageService } from './storage.service';
import { UserService } from './user.service';


const components = [
    
]

@NgModule({
    declarations: [components],
    exports: [components],
    imports: [ IonicModule, CommonModule],
    providers:[StorageService,HttpService,UserService]
    
})

export class ServicesModule { }