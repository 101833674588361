import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import {UserService} from '../modules/user/services/user.service'
import { AlertComponent } from '../components/alert/alert.component';


@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(public router: Router,public userService:UserService,public alertController: AlertController) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //return next.handle(req);
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          //console.log('event--->>>', event);
          // const body = event.body;
          // if(body.status=="ValidationError" || body.status=="InvalidRequest" || body.status=="AlreadyExists"){
          //   let alert = new AlertComponent(this.alertController);
          //   alert.errorAlert("", body.status, body.message);
          //    throwError(body);
          // }
        }
       // console.log("Res Event ", typeof(event), event);
        return event;
      }), catchError((error: HttpErrorResponse) => {

        //console.log("catchError ",error);
        if (error.status === 0) {
          let alert = new AlertComponent(this.alertController);
          alert.errorAlert("", "Connection Error", "Please Check Your Internet Connection!");
        }
        //auth failed
        if (error.status === 401) {
          this.userService.logout();
        }

        return throwError(error);
      }));


  }

  handleHttpError(error: HttpErrorResponse) {
    //console.log("handleHttpError",error);
    if (error.status === 0) {
      console.log("No internet erreor", error);
      let alert = new AlertComponent(this.alertController);
      alert.errorAlert("", "Connection Error", "Please Check Your Internet Connection!");

    }

    if (error instanceof HttpErrorResponse) {
      if (error.status === 401) {
        console.log("Unauthorized err ", error);
        this.userService.logout();
        //this.router.navigate(['/login']);
      }


    }
    return throwError(error);
  }




}
