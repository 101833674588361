import { Component } from '@angular/core';
//import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { Platform } from '@ionic/angular';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { AlertMsgProvider } from './modules/user/providers/alert-message.provider';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private alertMsgProvider:AlertMsgProvider,
    public platform:Platform) {

      if ((this.platform.is("android") || this.platform.is("ios")) && this.platform.is('hybrid')) {
        PushNotifications.addListener('pushNotificationReceived',(notification: PushNotificationSchema) => {
          this.alertMsgProvider.SuccessAlert("Notification Received",notification.title,notification.body)
        },);
      }
      

  }
}
