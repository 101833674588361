import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { AppointmentService } from '../../services/appointment.service';

@Component({
  selector: 'app-appintment-response',
  templateUrl: './appintment-response.component.html',
  styleUrls: ['./appintment-response.component.scss'],
})
export class AppintmentResponseComponent implements OnInit {
  selectedMenu: any;
  appointmentId:any;
  appointmentData:any;
  rejectForm: FormGroup;
  changeForm: FormGroup;

  constructor(public modalController: ModalController,private formBuilder: FormBuilder
    ,public appointmentService:AppointmentService,public loadingController: LoadingController, public alertController: AlertController ) { }

  ngOnInit() {
    this.rejectForm = this.formBuilder.group({
      "rejectReason": new FormControl('', [Validators.required]),
    })

    this.changeForm = this.formBuilder.group({
      "address": new FormControl(this.appointmentData.address, [Validators.required]),
      "reason": new FormControl('', [Validators.required]),
      "date": new FormControl(this.appointmentData.appointmentDateTime, [Validators.required]),
    })

    console.log(this.appointmentData);
  }

  dismiss(){
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  radioChanged(event){
    console.log(event)
    this.selectedMenu=event.value;
  }

  changeResponse(){
    let body={
      appointmentId:this.appointmentId,
      appointmentDateTime: this.changeForm.value.date,
      reason: this.changeForm.value.reason,
      address:this.changeForm.value.address
    }
    console.log(body);
    this.presentLoading().then(() => {
      console.log('here');
    this.appointmentService.addAppointmentChangeRequest(body).subscribe(data=>{
      console.log(data);
      this.loadingController.dismiss();
      if (data['status'] == "Ok") {
        let alert = new AlertComponent(this.alertController);
        alert.SuccessAlert("", "Request Sent", "Change Request Sent Successfully");
      } else {
        let alert = new AlertComponent(this.alertController);
        alert.errorAlert("", "Operation Failed", "Something wrong Please try again");
      }
    },(error)=>{
      this.loadingController.dismiss();
      console.log(error);
      let alert = new AlertComponent(this.alertController);
      alert.errorAlert("", "Operation Failed", "Something wrong Please try again");
    })

  })

  }

  reject(){
  //   let body={
  //     appointmentId:this.appointmentId,
  //     appointmentDateTime: this.changeForm.value.date.toISOString(),
  //     reason: this.changeForm.value.reason
  //   }
  //   console.log(body);
  //   this.presentLoading().then(() => {
  //   this.appointmentService.addAppointmentChangeRequest(body).subscribe(data=>{
  //     this.loadingController.dismiss();
  //     console.log(data);
  //     if (data['status'] == "Ok") {
  //       let alert = new AlertComponent(this.alertController);
  //       alert.SuccessAlert("", "Request Sent", "Change Request Sent Successfully");
  //     } else {
  //       let alert = new AlertComponent(this.alertController);
  //       alert.errorAlert("", "Operation Failed", "Something wrong Please try again");
  //     }
  //   },(error)=>{
  //     this.loadingController.dismiss();
  //     console.log(error);
  //     let alert = new AlertComponent(this.alertController);
  //     alert.errorAlert("", "Operation Failed", "Something wrong Please try again");
  //   })

  // })

  }

  accept(){
  //   let body={
  //     id:this.appointmentId,
  //     address:this.appointmentData.address,
  //     appointmentDateTime:this.appointmentData.appointmentDateTime,
  //     status: "Cancelled"
  //   }
  //   console.log(body);
  //   this.presentLoading().then(() => {
  //   this.appointmentService.updateAppointment(body).subscribe(data=>{
  //     this.loadingController.dismiss();
  //     console.log(data);
  //     if (data['status'] == "Ok") {
  //       let alert = new AlertComponent(this.alertController);
  //       alert.SuccessAlert("", "Appointment Cancelled", "Appointment Cancelled Successfully");
  //     } else {
  //       let alert = new AlertComponent(this.alertController);
  //       alert.errorAlert("", "Operation Failed", "Something wrong Please try again");
  //     }
  //   },(error)=>{
  //     this.loadingController.dismiss();
  //     console.log(error);
  //     let alert = new AlertComponent(this.alertController);
  //     alert.errorAlert("", "Operation Failed", "Something wrong Please try again");
  //   })

  // })

  }

  //show loading method
  async presentLoading() {
    const loading = await this.loadingController.create({
      message: 'Loading Please Wait',
      translucent: true,
    });
    return await loading.present();
  }

}
