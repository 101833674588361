import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  constructor(private httpService:HttpService) { }

  getAppointments(body){
    return this.httpService.post("RoadTest/GetMultipleAppointmentRecords",body);
  }
  getAppointment(id){
    return this.httpService.post("RoadTest/GetSingleAppointmentRecord", {id: id});
  }
  addAppointment(body){
    return this.httpService.post("RoadTest/AddAppointment",body);
  }

  updateAppointment(body){
    return this.httpService.post("RoadTest/UpdateAppointment",body);
  }

  getApplication(body){
    return this.httpService.post("RoadTest/GetMultipleHiringApplicationRecords",body);
  }

  addAppointmentChangeRequest(body){
    return this.httpService.post("RoadTest/AddAppointmentChangeRequest",body);
  }

  respondAppointmentChangeRequest(body){
    return this.httpService.post("RoadTest/RespondAppointmentChangeRequest",body);
  }

  createUser(body){
    return this.httpService.post("Temp/CreateInstructorTemp",body);
  }


}
