import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

  constructor() { }

  localStore(storageLable, storageValue) {
    localStorage.setItem(storageLable, JSON.stringify(storageValue));
  }

  getLocal(storageLable) {
    return localStorage.getItem(storageLable);
  }

  removeLocal(storageLable) {
    return localStorage.removeItem(storageLable);
  }
}
