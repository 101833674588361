import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import {UnauthenticatedGuard} from './guards/unauthenticated.guard';


const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./modules/roadTest/pages/home/home.module').then( m => m.HomePageModule),
    canLoad:[AuthGuard]
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    canActivate: [UnauthenticatedGuard],
    path: 'login',
    loadChildren: () => import('./modules/user/pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    canLoad:[AuthGuard],
    path: 'profile',
    loadChildren: () => import('./modules/user/pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    canLoad:[AuthGuard],
    path: 'appointments',
    loadChildren: () => import('./modules/roadTest/pages/appointments/appointments.module').then( m => m.AppointmentsPageModule)
  },
  {
    canLoad:[AuthGuard],
    path: 'road-tests',
    loadChildren: () => import('./modules/roadTest/pages/road-tests/road-tests.module').then( m => m.RoadTestsPageModule)
  },
  {
    canLoad:[AuthGuard],
    path: 'certificates',
    loadChildren: () => import('./modules/roadTest/pages/certificates/certificates.module').then( m => m.CertificatesPageModule)
  },
  {
    canLoad:[AuthGuard],
    path: 'add-road-test/:id/:instuctor/:appointmentid',
    loadChildren: () => import('./modules/roadTest/pages/add-road-test/add-road-test.module').then( m => m.AddRoadTestPageModule)
  },
  {
    canLoad:[AuthGuard],
    path: 'view-road-test',
    loadChildren: () => import('./modules/roadTest/pages/view-road-test/view-road-test.module').then( m => m.ViewRoadTestPageModule)
  },
  {
    canLoad:[AuthGuard],
    path: 'edit-road-test',
    loadChildren: () => import('./modules/roadTest/pages/edit-road-test/edit-road-test.module').then( m => m.EditRoadTestPageModule)
  },
  {
    canLoad:[AuthGuard],
    path: 'view-appointment',
    loadChildren: () => import('./modules/roadTest/pages/view-appointment/view-appointment.module').then( m => m.ViewAppointmentPageModule)
  },
  {
    canLoad:[AuthGuard],
    path: 'add-certificate',
    loadChildren: () => import('./modules/roadTest/pages/add-certificate/add-certificate.module').then( m => m.AddCertificatePageModule)
  },
  {
    path: 'view-notifications',
    loadChildren: () => import('./modules/roadTest/pages/view-notifications/view-notifications.module').then( m => m.ViewNotificationsPageModule)
  },
  {
    path: 'email-phone-verify',
    loadChildren: () => import('./modules/user/pages/email-phone-verify/email-phone-verify.module').then( m => m.EmailPhoneVerifyPageModule)
  },  {
    path: 'view-certificate',
    loadChildren: () => import('./modules/roadTest/pages/view-certificate/view-certificate.module').then( m => m.ViewCertificatePageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./modules/user/pages/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
