import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { UserProvider } from 'src/app/modules/user/providers/user.provider';
import { ApiResponse } from 'src/app/models/common.model';

@Component({
  selector: 'app-current-user',
  templateUrl: './current-user.component.html',
  styleUrls: ['./current-user.component.scss'],
})
export class CurrentUserComponent implements OnInit {
  userData:any
  constructor(public userProvider: UserProvider,
    private popoverController: PopoverController,
    public router:Router) { }

  ngOnInit() {
    if(!this.userData){
      this.getUserProfile();
    }
  }

  async logout(){
    await this.popoverController.dismiss();
    await this.userProvider.logout();
  }

  async closePop(){
    await this.popoverController.dismiss();
    await this.router.navigate(['/change-password']);
  }

  async closePopGoProfile(){
    await this.popoverController.dismiss();
    await this.router.navigate(['/profile']);
  }

  getUserProfile(){
    this.userProvider.getLoggedInUserProfile().subscribe((res: ApiResponse)=>{
      this.userData = res?.output
    })
  }
}
