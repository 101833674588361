import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { UserProvider } from '../../providers/user.provider';

@Component({
  selector: 'app-update-pro-pic',
  templateUrl: './update-pro-pic.component.html',
  styleUrls: ['./update-pro-pic.component.scss'],
})
export class UpdateProPicComponent implements OnInit {
  img;
  base64img;
  file: File=null;
  constructor(public modalController: ModalController,public userProfileProvider:UserProvider,
    public loadingController: LoadingController,
    public alertController: AlertController) { }

  ngOnInit() {}

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  onFileSelected(event){
    this.file=<File>event.target.files[0];

  }

   upload(){
    let formdata=new FormData();
    formdata.append('profilePic',this.file,this.file.name);
    
    this.presentLoading().then(() => {
      this.userProfileProvider.updateProPic(formdata).subscribe(data=>{
        console.log(data);
        this.loadingController.dismiss();
        if (data['status'] == "Ok") {
          let alert = new AlertComponent(this.alertController);
          alert.SuccessAlert("", "Image Uploaded", "Profile Picture Updated Successfully");
        }else{
          let alert = new AlertComponent(this.alertController);
          alert.errorAlert("", "Upload Failed", "Something wrong Please try again");
        }
      },(error)=>{
        this.loadingController.dismiss();
        let alert = new AlertComponent(this.alertController);
        alert.errorAlert("", "Upload Failed", "Something wrong Please try again");
      })
    })
    
  }

    //show loading method
    async presentLoading() {
      const loading = await this.loadingController.create({
        message: 'Image Uploading',
        translucent: true,
      });
      return await loading.present();
    }

}


