import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { from, Observable } from 'rxjs';
import { StorageService } from '../modules/roadTest/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  // canLoad(
  //   route: Route,
  //   segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return true;
  // }

  constructor(public storageService:StorageService,public router:Router){

  }

  canLoad(){
    if(this.storageService.getLocal('user')){
      return true;
    }else{
      this.router.navigate(['/login']);
      return false
    }
  }


}
