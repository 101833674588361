import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-select-vehicle',
  templateUrl: './select-vehicle.component.html',
  styleUrls: ['./select-vehicle.component.scss'],
})
export class SelectVehicleComponent implements OnInit {
  searchText: any;

  vehicles= [];
  type: string;
  searchBy = 'all';
  vehiclesFiltered: any[];
  private showAutocomplete: boolean;
  constructor( private modalController: ModalController) { }

  ngOnInit() {
    this.vehiclesFiltered = this.vehicles;
  }

  doAutocomplete(event) {
    if (this.searchBy === 'vin' ) {
      this.vehiclesFiltered = this.vehicles.filter(d => d.vin.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1);
    } else if (this.searchBy === 'unit' ) {
      this.vehiclesFiltered = this.vehicles.filter(d => d.unit && d.unit.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1);
    } else if (this.searchBy === 'licPlate' ) {
      this.vehiclesFiltered = this.vehicles.filter(d => d.licPlate.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1);
    } else {
        this.vehiclesFiltered = this.vehicles.filter(d => d.vin.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1);
        if (this.vehiclesFiltered.length === 0) {
          this.vehiclesFiltered = this.vehicles.filter(d => d.unit && d.unit.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1);
          if (this.vehiclesFiltered.length === 0) {
            this.vehiclesFiltered = this.vehicles.filter(d => d.licPlate.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1);
          }
        }

    }
    // this.driversFiltered.forEach(driver => {
    //   driver.selected = false;
    // });
    this.showAutocomplete = true;
  }

  searchByChanged(event: any) {

    this.searchBy = event.detail.value;
    this.searchText = '';
  }

  async selectVehicle(vehicle: any, $event: MouseEvent) {
    this.vehiclesFiltered.forEach(ele => {
      ele.selected = false;
    });
    await this.modalController.dismiss(vehicle);
  }

  cancel() {
    this.modalController.dismiss(null);
  }
}
