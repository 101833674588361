import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SignaturePad } from 'angular2-signaturepad';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
})
export class SignaturePadComponent implements OnInit {
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  @ViewChild('box') box: ElementRef;
  signature;

  constructor(public modalController: ModalController, public storageService: StorageService) { }

  ngOnInit() {
    //get signature image from local storage
    this.signature = JSON.parse(this.storageService.getLocal('signature'));
  }

  ionViewDidEnter() {
    //set signature image to pad
    this.box.nativeElement.style.cssText = `background:url("${this.signature}")`;
  }

  //signature pad settings
  public signaturePadOptions: Object = {
    'minWidth': 2,        //pen size
    'canvasWidth': 700,   //width
    'canvasHeight': 200   //height
  };

  drawComplete() {
    this.signature = this.signaturePad.toDataURL();
  }

  drawStart() {
    console.log('begin drawing');
  }

  //this modal close
  dismiss() {
    let data = {
      button: 'close',
    }
    this.modalController.dismiss(data);
  }

  //done button function
  done() {
    let data = {
      button: 'save',
      signature:this.signature
    }
    this.modalController.dismiss(data);
  }

  //clear button function
  clear() {
    this.signaturePad.clear();
    this.box.nativeElement.style.background = 'white';
  }

}
